<template>
  <jet-map
    ref="map"
    name="mapbox-provider"
    v-on:onload="_mapLoad">
  </jet-map>
</template>

<script>
import MapBoxGL from 'mapbox-gl';
import MapSettingsService from '@/components/dev/service/MapSettingsService';
import { rgbaFromHexa, colorFromInt } from '@/utils/utils';
import JetMap from '@/components/map';
import MapIcons from '@/components/dev/service/MapIcons';
import MapBoxCircle from 'mapbox-gl-circle';
import MapObjectsService from '@/components/dev/service/MapObjectsService';
import {SPEED_LIMIT} from '@/const/vehicle_consts';

let _map;

let _points = [];

// Идентификатор источника и слоя для всех точек машинок
const _STATIC_VEHICLES_ID = 'static-vehicles';

// Идентификатор источника и слоя для трека
const _TRACK_PREFIX = 'track-vehicle.';

// Пустой список коллекции точек
const _EMPTY_FEATURE_COLLECTION = {
  type: 'FeatureCollection',
  features: [],
};

const _stop_e = (e)=>{
    var e = e?.originalEvent || e;
    if (!!e){
        e.preventDefault();
        e.stopPropagation();
    }
};

// --------------------- СЛОИ НА КАРТЕ ---------------------

// Идентификаторы гео зон
let _geoZonesId = [];
let _geoZoneCircles = [];
let _geoZoneCirclesHash = {};

let _layerRoutes = [];
let _layerStops = [];

// ----------------------------------------------------------

function distance(ll1, ll2) { // TODO: ->maputils
  const R = 6372795; // радиус Земли

  if (
    (!ll1)
    || (!ll2)
    || !(!!ll1.lat)
    || !(!!ll2.lat)
  ) {
    return R;
  }
  // перевод коордитат в радианы
  const lat1 = ll1.lat * Math.PI / 180,
    lat2 = ll2.lat * Math.PI / 180,
    long1 = ll1.lon * Math.PI / 180,
    long2 = ll2.lon * Math.PI / 180;

  // вычисление косинусов и синусов широт и разницы долгот
  const cl1 = Math.cos(lat1);
  const cl2 = Math.cos(lat2);
  const sl1 = Math.sin(lat1);
  const sl2 = Math.sin(lat2);
  const delta = long2 - long1;
  const cdelta = Math.cos(delta);
  const sdelta = Math.sin(delta);

  // вычисления длины большого круга
  const y = Math.sqrt(Math.pow(cl2 * sdelta, 2) + Math.pow(cl1 * sl2 - sl1 * cl2 * cdelta, 2));
  const x = sl1 * sl2 + cl1 * cl2 * cdelta;
  const ad = Math.atan2(y, x);
  const dist = ad * R; // расстояние между двумя координатами в метрах

  return Math.round(dist);
}

/**
 * Провайдер для работы с картой MapBox
 */
export default {
  components: { JetMap },
  name: 'MapBoxMapProvider',
  inject: [
    'trackPointClick', 'vehiclePointClick',
  ],
  data(){
      return {
          loaded: false
      };
  },
  mounted() {
      this.$nextTick(()=>{
          _map = this.$refs['map'];
      });
  },
  methods: {
    /**
     * Map loaded: 1.add image`s
     */
    _mapLoad(e) {
      (new MapIcons(e)).init();
      this.loaded = true;
    },
    // Рисование машинки
    drawVehicle(vehicle) {
      console.log('drawVehicle');
      if (vehicle.ll != null) {
        const latLng = vehicle.ll.split(':');

        const lat = latLng[0];
        const lon = latLng[1];

        _points.push({
          ...vehicle,
          lat,
          lon,
        });

        _map.createOrDataSource(_STATIC_VEHICLES_ID, {
          type: 'FeatureCollection',
          features: _points.map(it => {
            return {
              type: 'Feature',
              properties: {
                vehicle: it,
              },
              geometry: {
                type: 'Point',
                properties: {},
                coordinates: [it.lon, it.lat],
              },
            };
          }),
        });

        _map.createOrDataLayer(_STATIC_VEHICLES_ID, {
          id: _STATIC_VEHICLES_ID,
          type: 'symbol',
          source: _STATIC_VEHICLES_ID,
          layout: {
            'text-field': ['get', 'govnum', ['get', 'vehicle']],
            'text-justify': 'auto',
            'text-size': 11,
            'text-offset': [0, 2],
            'text-allow-overlap': true,
            'icon-image': ['case',
              ['==', ['get', 'tracking', ['get', 'vehicle']], true], 'bus-moving',
              'bus-parking',
            ],
            'icon-allow-overlap': true,
            'icon-rotate': ['get', 'heading', ['get', 'vehicle']],
            'icon-size': 0.3,
            'icon-offset': [0, 20],
            'icon-ignore-placement': true,
            'icon-rotation-alignment': 'map',
          },
          'paint': {
            'text-color': '#202',
            'text-halo-color': '#fff',
            'text-halo-width': 2,
          },
        });

        if (!vehicle.tracking) {
          _map.getRawMap().panTo([lon, lat]);
        }

        // Помечаем что ТС нарисовано
        vehicle.__state = {
          ...vehicle.__state || {},
          draw: true,
          tracking: vehicle.tracking || false,
        };

        // Обработка клика по ТС
        _map.getRawMap().on('click', _STATIC_VEHICLES_ID, (e)=>{
          console.log('click', e);
          _stop_e(e);
          const features = _map.getRawMap().queryRenderedFeatures(e.point, {
            layers: [_STATIC_VEHICLES_ID]
          });

          if (features?.length > 0) {
            const properties = features[0].properties || {};
            const vehicle = JSON.parse(properties['vehicle'] || {});
            console.log('click(2)', vehicle);
            console.log('e', e);
            this.vehiclePointClick(vehicle, e.point);
          }
        });
        return vehicle;
      } else {
        jet.msg({
          text: `У ${vehicle.govnum.toUpperCase()} нет координат!`,
          color: 'red',
        });

        console.log(`У ${vehicle.govnum.toUpperCase()} нет координат!`);

        return null;
      }
    },
    // Удаление машинки
    removeVehicle(vehicle) {
      console.log('removeVehicle');
      const index = _points.findIndex(it => it.id === vehicle.id);

      if (index > -1) {
        _points.splice(index, 1);

        const features = _map.getSourceFeatures(_STATIC_VEHICLES_ID) || [];

        features.splice(
          features.findIndex(it => it.properties.vehicle.id === vehicle.id),
          1,
        );

        _map.createOrDataSource(_STATIC_VEHICLES_ID, {
          type: 'FeatureCollection',
          features: features,
        });
      }

      // Помечаем что ТС не нарисовано
      vehicle.__state = {
        ...vehicle.__state || {},
        draw: false,
        tracking: vehicle.tracking,
      };

      return vehicle;
    },
    // Очистка всех машинов
    clearVehicles() {
      console.log('clearVehicles');
      _points.forEach(vehicle => {
        // Помечаем что ТС не нарисовано
        vehicle.__state = {
          ...vehicle.__state || {},
          draw: false,
        };
      });

      _points = [];

      _map.createOrDataSource(_STATIC_VEHICLES_ID, {
        type: 'FeatureCollection',
        features: [],
      });

      return 0;
    },
    // Рисование трека
    drawTrack(vehicle, track, markers, settings) {
      
      console.log('drawTrack');
      const id = `${_TRACK_PREFIX}${vehicle.id}`;
      const speedLimitId = `${id}.speed-limit`;
      const parkingId = `${id}.parking`;
      const stopingId = `${id}.stoping`;
      const trackPoints = `${id}.points`;
      const trackPointsSelected = `${id}.points-selected`;

      // Источник линий
      _map.createOrDataSource(id, {
        type: 'FeatureCollection',
        features: [
          {
            type: 'Feature',
            properties: {
              isSpeedLimit: false,
            },
            geometry: {
              type: 'LineString',
              coordinates: track.map(it => {
                return [it.lon, it.lat];
              }),
            },
          },
        ],
      });

      let prev = { lat: 0, lon: 0 };

      // Источник точек трека (+фильтрация ежика, +нач./кон./ост./стоя.)
      _map.createOrDataSource(trackPoints, {
        type: 'FeatureCollection',
        features: track.map((it, n) => {
          it.firstOverspeed = false;
          if (it.status === 'OVERSPEEDING') {
            if (n == 0) {
              it.firstOverspeed = true;
              it.overspeed = parseInt(it.speed);
            } else if (n > 0 && track[n-1].status !== 'OVERSPEEDING') {
              it.firstOverspeed = true;
              it.overspeed = parseInt(it.speed);
            }
          }
          //type normalize
          if (n === 0) {
            it.type = 'started';
          } else if (n === (track.length - 1)) {
            it.type = 'ended';
          } else if (!(!!it.type)) {
            it.type = 'moving';
          }

          it.distance = distance(prev, it);

          it.icon = ('started' === it.type)
            ? 'map-track-a'
            : ('ended' === it.type)
              ? 'map-track-b'
              : ('moving' === it.type)
                ? 'arrow-direction'
                : '';

          switch (it.icon) {
            case 'map-track-a':
            case 'map-track-b':
              it.ioffset = [32, -40];
              it.heading = 0;
              it.distance = 65000;
              break;

            case 'map-track-pause':
            case 'map-track-stop':
              it.ioffset = [32, -40];
              it.heading = 0;
              it.distance = 65000;
              break;

            default:
              it.heading -= 90;
              break;
          }

          if (it.distance > 20) {
            prev = it;
          }

          return {
            id: it.id,
            type: 'Feature',
            properties: {
              point: it,
              isSelected: false,
              status: it.status,
            },
            geometry: {
              type: 'Point',
              coordinates: [it.lon, it.lat],
            },
          };

        }),
      });

      // Линия трека
      _map.createOrDataLayer(id, {
        id: id,
        type: 'line',
        source: id,
        paint: {
          'line-color': '#0f5fbb',
          'line-width': 4,
        },
        filter: ['==', 'isSpeedLimit', false],
        layout: {
          'line-cap': 'round',
          'line-join': 'round',
        },
      });

      // Точки трека
      _map.createOrDataLayer(trackPoints, {
        id: trackPoints,
        type: 'symbol',
        source: trackPoints,
        layout: {
          'icon-image': ['get', 'icon', ['get', 'point']],
          'icon-size': 0.4,
          'icon-rotate': ['get', 'heading', ['get', 'point']],
          'icon-rotation-alignment': 'map',
          'icon-allow-overlap': true,
          'icon-offset': ['get', 'ioffset', ['get', 'point']],
        },
        filter: [
          '>', ['get', 'distance', ['get', 'point']], [
            'step', ['zoom'],
            500, 10,
            400, 11,
            300, 12,
            200, 13,
            50, 14,
            20,
          ],
        ],
      });

      // Выбранные точки
      _map.createOrDataLayer(trackPointsSelected, {
        id: trackPointsSelected,
        type: 'circle',
        source: trackPoints,
        paint: {
          'circle-radius': 4,
          'circle-stroke-width': 2,
          'circle-color': '#dc1794',
          'circle-stroke-color': '#3b5d9c',
        },
        filter: [
          '==',
          'isSelected',
          true,
        ],
      });

      // Остановки
      _map.createOrDataLayer(stopingId, {
        id: stopingId,
        type: 'symbol',
        source: trackPoints,
        layout: {
          'icon-image': 'map-track-pause',
          'icon-size': 0.4,
          'icon-rotate': 0,
          'icon-rotation-alignment': 'map',
          'icon-allow-overlap': true,
          'icon-offset': [32, -40],
        },
        filter: [
          '==', ['get', 'status', ['get', 'point']], 'STOP'
        ],
      });

      // Стоянки
      _map.createOrDataLayer(parkingId, {
        id: parkingId,
        type: 'symbol',
        source: trackPoints,
        layout: {
          'icon-image': 'map-track-stop',
          'icon-size': 0.4,
          'icon-rotate': 0,
          'icon-rotation-alignment': 'map',
          'icon-allow-overlap': true,
          'icon-offset': [32, -40],
        },
        filter: [
          '==', ['get', 'status', ['get', 'point']], 'PARKING'
        ],
      });

      // Превышение скорости
      _map.createOrDataLayer(speedLimitId, {
        id: speedLimitId,
        type: 'symbol',
        source: trackPoints,
        layout: {
          'icon-image': 'map-overspeed',
          'icon-size': 0.4,
          'icon-rotate': 0,
          'icon-rotation-alignment': 'map',
          'icon-allow-overlap': true,
          'icon-offset': [32, -40],
          'text-field': ['get', 'overspeed', ['get', 'point']],
          'text-size': 14,
          'text-offset': [1, -1.9],
        },
        paint: {
          'text-color': '#ffffff',
          'text-halo-color': '#ffffff',
        },
        filter: [
          '==', ['get', 'firstOverspeed', ['get', 'point']], true
        ],
      });

      // Обработка клика по точке трека
      _map.getRawMap().on('click', trackPoints, (e) => {
        const features = _map.getRawMap().queryRenderedFeatures(e.point, {
          layers: [trackPoints],
        });

        if (features && features[0] != null) {
          const properties = features[0].properties || {};
          const point = JSON.parse(properties['point'] || {});
          this.trackPointClick(vehicle, point);
        }
      });

      // "Вписывание" размеров карты в область видимости
      const bounds = new MapBoxGL.LngLatBounds(
        [track[0].lon, track[0].lat],
        [track[0].lon, track[0].lat],
      );

      //console.log('bounds', bounds);

      (track || []).forEach((it, i) => {
        const data = [it.lon, it.lat];

        if (i) {
          bounds.extend(data);
        }
      });

      _map.getRawMap().fitBounds(bounds, {
        padding: 20,
      });

      this.changeTrackStyle(vehicle, settings);

      vehicle.__state = {
        ...vehicle.__state,
        track: true,
      };
    },
    // Рисование трека по слежению
    drawTrackingLine(vehicle, track, settings) {
      console.log('drawTrackingLine');
      const id = `${_TRACK_PREFIX}${vehicle.id}`;
      const trackPoints = `${id}.points`;

      // Источник линий
      _map.createOrDataSource(id, {
        type: 'FeatureCollection',
        features: [
          {
            type: 'Feature',
            properties: {
              isSpeedLimit: false,
            },
            geometry: {
              type: 'LineString',
              coordinates: track.map(it => {
                return [it.lon, it.lat];
              }),
            },
          },
        ],
      });

      // Источник точек трека (+фильтрация ежика, +нач./кон./ост./стоя.)
      _map.createOrDataSource(trackPoints, {
        type: 'FeatureCollection',
        features: track.map((it, n) => {
          if (parseInt(it.speed) > SPEED_LIMIT) {
            it.status = 'OVERSPEEDING';
          }

          return {
            id: it.id,
            type: 'Feature',
            properties: {
              point: it,
              isSelected: false,
              status: it.status,
            },
            geometry: {
              type: 'Point',
              coordinates: [it.lon, it.lat],
            },
          };

        }),
      });
      
      // Линия трека
      _map.createOrDataLayer(id, {
        id: id,
        type: 'line',
        source: id,
        paint: {
          'line-color': '#0f5fbb',
          'line-width': 4,
        },
        filter: ['==', 'isSpeedLimit', false],
        layout: {
          'line-cap': 'round',
          'line-join': 'round',
        },
      });

      this.changeTrackStyle(vehicle, settings);

      vehicle.__state = {
        ...vehicle.__state,
        track: true,
      };
    },
    // Удаление трека
    removeTrack(vehicle) {
      console.log('removeTrack');
      const id = `${_TRACK_PREFIX}${vehicle.id}`;
      const trackPoints = `${id}.points`;

      _map.createOrDataSource(id, _EMPTY_FEATURE_COLLECTION);
      _map.createOrDataSource(trackPoints, _EMPTY_FEATURE_COLLECTION);

      vehicle.__state = {
        ...vehicle.__state,
        track: false,
      };
    },
    // Удаление всех треков
    clearTracks() {
      console.log('clearTracks');
      const re = new RegExp(`${_TRACK_PREFIX}`);

      Object.keys(_map.getRawMap().getStyle().sources)
        .filter(it => re.test(it))
        .forEach(it => {
          _map.createOrDataSource(it, {
            type: 'FeatureCollection',
            features: [],
          });
        });
    },
    // Подлет к точке трека
    flyTo(vehicle, trackPoint) {
      console.log('flyTo');
      if (trackPoint.lat != null && trackPoint.lon != null) {
        const id = `${_TRACK_PREFIX}${vehicle.id}`;
        const trackPoints = `${id}.points`;

        const points = _map.getSourceFeatures(trackPoints);

        // Снятие выделения с прошлой точки
        const oldSelectedIndex = points.findIndex(it => it.properties.isSelected);
        if (oldSelectedIndex > -1) {
          points[oldSelectedIndex].properties.isSelected = false;
        }

        // Установка новой выбранной точки
        const trackPointIndex = points.findIndex(it => it.id === trackPoint.id);
        if (trackPointIndex > -1) {
          points[trackPointIndex].properties.isSelected = true;
        }

        _map.createOrDataSource(trackPoints, {
          type: 'FeatureCollection',
          features: points,
        });

        _map.getRawMap().setCenter([trackPoint.lon, trackPoint.lat]);
      }
    },
    // Установка фокуса на координатах
    flyToCoordinates(lat, lon) {
      console.log('flyToCoordinates');
      if (lat !== null && lon !== null) {
        _map.getRawMap().setCenter([lon, lat]);
      }
    },
    setZoom(zoom){
        console.log('setZoom');
        _map.getRawMap().setZoom(zoom);
    },
    update(){
      console.log('update');
        if (!!_map){
            const r = _map.getRawMap();
            if (!!r){
                r.resize.apply(r);
            }
        }
    },
    // Изменение настроек трека
    changeTrackStyle(vehicle, settings) {
      console.log('changeTrackStyle');
      const id = `${_TRACK_PREFIX}${vehicle.id}`;
      const speedLimitId = `${id}.speed-limit`;
      const parkingId = `${id}.parking`;
      const stopingId = `${id}.stoping`;

      const visibilityField = 'visibility';
      const stopTypes = settings.stopTypes;

      const trackColor = settings.color || '#0000FFFF';
      const lineWidth = settings.width;

      const showBounds = [
        MapSettingsService.stopsEnum.all,
        MapSettingsService.stopsEnum.stops,
      ].includes(settings.showStops) ? 'visible' : 'none';

      const showStops = stopTypes.stop || false ? 'visible' : 'none';
      const showSpeedUps = stopTypes.speedUp || false ? 'visible' : 'none';
      const showNoData = stopTypes.noData || false ? 'visible' : 'none';
      const showMoving = stopTypes.moving || false ? 'visible' : 'none';
      const showParking = stopTypes.parking || false ? 'visible' : 'none';

      const m = _map.getRawMap();

      // Настройка трека
      m.setPaintProperty(id, 'line-color', rgbaFromHexa(trackColor));
      m.setPaintProperty(id, 'line-width', lineWidth);

      // // Настройка показа начала и конца трека
      // m.setLayoutProperty(layers.busStartId, visibilityField, showBounds);
      // m.setLayoutProperty(layers.busEndId, visibilityField, showBounds);
      //
      // // Настройка показа маркеров
      if (m.getLayer(stopingId)) {
        m.setLayoutProperty(stopingId, visibilityField, showStops);
      }
      if (m.getLayer(speedLimitId)) {
        m.setLayoutProperty(speedLimitId, visibilityField, showSpeedUps);
      }
      // m.setLayoutProperty(layers.trackNoDataId, visibilityField, showNoData);
      // m.setLayoutProperty(layers.trackPointsId, visibilityField, showMoving);
      if (m.getLayer(parkingId)) {
        m.setLayoutProperty(parkingId, visibilityField, showParking);
      }
    },

    // --------------------- EXPERIMENTAL ----------------------

    // TODO: Использовать только в крайней необходимости!
    getRawMap() {
      return _map;
    },

    // --------------------- Слои на карте ---------------------

    // Отрисовка гео зон
    drawGeoZones(geoZones) {
      console.log('drawGeoZone');
      _geoZonesId.forEach(it => {
        if (_map.getRawMap().getLayer(it)) {
          _map.getRawMap().removeLayer(it);
        }

        if (_map.getRawMap().getSource(it)) {
          _map.getRawMap().removeSource(it);
        }
      });

      _geoZoneCircles.forEach(it => {
        it.remove();
      });

      _geoZonesId = [];
      _geoZoneCircles = [];
      _geoZoneCirclesHash = {};

      geoZones.forEach(it => {
        const item = _copy(it);
        const ref = item.ref;
        const geometry = item.refGeometry;

        delete item.ref;
        delete item.refType;
        delete item.refGeometry;

        const id = `geo_zone_${ref.id}`;

        _geoZonesId.push(id);

        _map.createOrDataSource(id, {
          type: 'FeatureCollection',
          features: [item],
        });

        switch (it.refType) {
          case 'LINE':
            const radians = (
              78271.517 *
              Math.cos(
                item.geometry.coordinates[0][1] * Math.PI / 180,
              )
            );

            _map.createOrDataLayer(id, {
              id: id,
              type: 'line',
              source: id,
              layout: {
                'line-join': 'round',
                'line-cap': 'round',
              },
              paint: {
                'line-color': colorFromInt(ref.color || -65536, 1),
                'line-width': [
                  'interpolate',
                  ['exponential', 2],
                  ['zoom'],
                  1, ['*', ref.width / radians, ['^', 2, 1]],
                  24, ['*', ref.width / radians, ['^', 2, 24]],
                ],
              },
            });
            

            break;

          case 'POLYGON':
            // TODO: пока не очень красиво, но это проще и работает
            _map.createOrDataSource(id, item);

            _map.createOrDataLayer(id, {
              id: id,
              type: 'fill',
              source: id,
              paint: {
                'fill-color': colorFromInt(ref.color || -65536, 0.7),
              },
            });

            break;

          case 'CIRCLE':
            const circle = new MapBoxCircle(
              { lat: geometry.y, lng: geometry.x },
              (ref.width || 1000),
              {
                fillColor: colorFromInt(ref.color || -65536, 0.8),
                strokeColor: colorFromInt(ref.color || -65536, 1),
              },
            );

            _geoZoneCircles.push(circle);
            _geoZoneCirclesHash[it.ref.id] = {
              geoZone: it,
              circle: circle,
            };

            circle.addTo(_map.getRawMap());

            break;
        }
      });
    },
    // Отрисовка маршрутов
    drawLayerRoutes(routes) {
      console.log('drawLayerRoutes');
      _layerRoutes.forEach(it => {
        _map.createOrDataSource(it, {
          type: 'FeatureCollection',
          features: [],
        });
      });
      
      _layerStops.forEach(it => {
        _map.createOrDataSource(it, {
          type: 'FeatureCollection',
          features: [],
        });
      });

      _layerRoutes = [];
      _layerStops = [];

      let bounds = null;

      routes.forEach(it => {
        const coords = (it.points || []).map(it => it.coordinates);

        if (bounds === null) {
          bounds = new MapBoxGL.LngLatBounds(coords[0], coords[0]);
        }

        for (let i = 1, len = coords.length; i < len; i++) {
          bounds.extend(coords[i]);
        }
        
        // Конечные остановки
        const rpEndId = `route_points_end_stops${it.id}`;
        _map.createOrDataSource(rpEndId, {
           type: 'FeatureCollection',
           features: it.points.filter((p) => p.isFinal).map(p => {
             return {
               type: 'Feature',
               properties: {
                 name: p.name || p.locationName || p.locname,
               },
               geometry: {
                 type: 'Point',
                 coordinates: p.coordinates,
               },
             };
           }),
         });
        
        _map.createOrDataLayer(rpEndId, {
           id: rpEndId,
           type: 'circle',
           source: rpEndId,
           paint: {
             'circle-radius': 8,
             'circle-stroke-width': 2,
             'circle-color': '#ff00de',
             'circle-stroke-color': '#0032e7',
           },
         });
        
        _map.createOrDataLayer(`route_points_end_stops_text_${it.id}`, {
           id: `route_points_end_stops_text_${it.id}`,
           type: 'symbol',
           source: rpEndId,
           layout: {
             'text-field': ['get', 'name'],
             'text-variable-anchor': ['bottom'],
             'text-radial-offset': 0.5,
             'text-justify': 'auto',
             'text-size': 14,
           },
         });
         
        _layerStops.push(rpEndId);
        
        // Промежуточные остановки
        const rpId = `route_points_stops${it.id}`;
        _map.createOrDataSource(rpId, {
           type: 'FeatureCollection',
           features: it.points.filter((p) => (!p.isFinal && p.location !== null)).map(p => {
             return {
               type: 'Feature',
               properties: {
                 name: p.name || p.locationName || p.locname,
               },
               geometry: {
                 type: 'Point',
                 coordinates: p.coordinates,
               },
             };
           }),
         });
        
        _map.createOrDataLayer(rpId, {
           id: rpId,
           type: 'circle',
           source: rpId,
           paint: {
             'circle-radius': 8,
             'circle-stroke-width': 2,
             'circle-color': '#FFA812',
             'circle-stroke-color': '#FFA812',
           },
         });
        
        const rpSt = `route_points_stops_text_${it.id}`
        _map.createOrDataLayer(rpSt, {
           id: rpSt,
           type: 'symbol',
           source: rpId,
           layout: {
             'text-field': ['get', 'name'],
             'text-variable-anchor': ['bottom'],
             'text-radial-offset': 0.5,
             'text-justify': 'auto',
             'text-size': 14,
           },
         });
         
        _layerStops.push(rpId);
        
        const rId = `route_${it.id}`;

        _layerRoutes.push(rId);

        _map.createOrDataSource(rId, {
          type: 'Feature',
          geometry: {
            type: 'LineString',
            coordinates: coords,
          },
        });

        _map.createOrDataLayer(rId, {
          id: `route_${it.id}`,
          type: 'line',
          source: rId,
          layout: {
            'line-join': 'round',
            'line-cap': 'round',
          },
          paint: {
            'line-color': '#0000FF',
            'line-width': 4,
          },
        });
        if (_map.getRawMap().getLayer(_STATIC_VEHICLES_ID)) {
          _map.getRawMap().moveLayer(rId, _STATIC_VEHICLES_ID);
          _map.getRawMap().moveLayer(rpSt, _STATIC_VEHICLES_ID);
          _map.getRawMap().moveLayer(rpEndId, _STATIC_VEHICLES_ID);
          _map.getRawMap().moveLayer(rpId, _STATIC_VEHICLES_ID);
        }
      });

      if (bounds !== null) {
        _map.getRawMap().fitBounds(bounds, {
          padding: 20,
        });
      }
    },
    // Отрисовка остановок
    drawStops(stops) {
      console.log('drawStops');
      _map.createOrDataSource('route_stops', {
        type: 'FeatureCollection',
        features: stops.map(it => ({
          type: 'Feature',
          properties: {
            title: it.locName || 'Нет имени остановки',
          },
          geometry: {
            type: 'Point',
            coordinates: it.coordinates,
          },
        })),
      });

      _map.createOrDataLayer('route_stops', {
        id: 'route_stops',
        type: 'circle',
        source: 'route_stops',
        paint: {
          'circle-color': '#ff00de',
          'circle-radius': 5,
          'circle-stroke-width': 2,
          'circle-stroke-color': '#0032e7',
        },
      });

      _map.createOrDataLayer('route_stops_text', {
        id: 'route_stops_text',
        type: 'symbol',
        source: 'route_stops',
        layout: {
          'text-field': ['get', 'title'],
          'text-variable-anchor': ['top', 'bottom', 'left', 'right'],
          'text-radial-offset': 0.5,
          'text-justify': 'auto',
          'text-size': 14,
        },
      });
    },
    // Отрисовка объектов маршрута
    drawStopObjects(stopObjects) {
      console.log('drawStopObjects',stopObjects);
      MapObjectsService.init(_map);

      MapObjectsService.drawStopObjects(
        _copy(stopObjects || []),
      );
    },
    // Отрисовка флага на выделенной точке трека
    drawFlag(point) {
      console.log('drawFlag');
      try {
        _map.createOrDataSource("flag-point", {
          type: 'FeatureCollection',
          features: [
          {
            type: 'Feature',
            geometry: {
              type: 'Point',
              coordinates: [point.lon, point.lat],
            },
          },]
        });

        _map.createOrDataLayer("flag-point", {
          id: "flag-point",
          type: 'symbol',
          source: "flag-point",
          layout: {
            'icon-image': 'flag-red',
            'icon-allow-overlap': true,
            'icon-size': 0.15,
            'icon-offset': [80, -110],
            'icon-rotation-alignment': 'map',
          },
        });
        return point;
      } catch(e) {
        console.log("ERR (flag)", e);
        return null;
      }
    },
    // Удаление флага
    removeFlag() {
      console.log('removeFlag');
      try {
        _map.createOrDataSource("flag-point", {
          type: 'FeatureCollection',
          features: []
        });
        return true;
      } catch (ex) {
        console.log("Ошибка при удалении флага на выделенной точке трека", ex);
        return false;
      }
    },
    async ready(){
        const self = this;
        var n = 0;
        return new Promise((resolve, reject)=>{
            const _wait = ()=>{
                n++;
                if ( n > 100 ){
                    reject({message: "timeout expired"});
                }
                if (!!self.loaded){
                    setTimeout(()=>{resolve();}, 300);
                } else {
                    setTimeout(_wait, 300);
                }
            };  //_wait
            _wait();
        });
    }   //ready
  }
};
</script>